.body {
    display: flex;
    height: 100vh;
    min-height: 650px;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
