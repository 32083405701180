.app-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.app-logo {
    padding-top: 52px;
    padding-bottom: 20px;
    max-width: 160px;
}

.app-section {
    text-align: center;
    border-radius: 1.5rem;
    box-shadow: 0 25px 50px -12px rgb(0 0 0/0.25);
    background-color: #ffffff;
    padding-inline: 0,75rem;
    padding-block: 1rem;
    margin-block: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 42rem;
    background-color: #77c7eb;
}
.app-section-bis {
    text-align: center;
    border-radius: 1.5rem;
    box-shadow: 0 25px 50px -12px rgb(0 0 0/0.25);
    background-color: #154E76;
    padding-inline: 0, 75rem;
    padding-block: 1rem;
    margin-block: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 42rem;
    background-color: #77c7eb;
}

.app-emma {
    transform: scale(0.8);
}

.app-title {
    color: #ffffff;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.75rem;
    width: 90%;
    padding-bottom: 16px;
    margin: 0;
}
.app-small {
    padding-bottom: 2.5rem;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
    color: #154e76;
}
.app-button {
    background-color: black;
    padding: 0;
    margin-inline: 8px;
    margin-block: 2px;
    border: none;
    border-radius: 9999px;
}

@media (min-width: 915px) {
    .app-section {
        padding-inline: 0;
        padding-block: 2rem;
        width: 75%;
    }
    .app-section-bis {
        padding-inline: 0;
        background-color: #154E76;
        padding-block: 2rem;
        width: 75%;
    }
    .app-title {
        font-size: 1.875rem;
        line-height: 2.25rem;
        width: 80%;
        padding-bottom: 1.5rem;
    }
    .app-small {
        padding-bottom: 1.75rem;
    }
    .app-emma {
        transform: scale(1);
        padding-bottom: 32px;
    }
}

@media (min-width: 1280px) {
    .app-section {
        width: 50%;
    }
}
