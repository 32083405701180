body {
  margin: 0;
    font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiInput-underline:after{
    border-bottom: 1px solid #3691D0!important;
}
.MuiButton-root {
    text-transform: none!important;
}

.body {
    display: flex;
    height: 100vh;
    min-height: 650px;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

body{
    margin-top: -20px;
    background-color: #DEF2FF;
    height: 100%;
    overflow-x: hidden;
}
.aside{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}
.article{
    display: flex;
    width: 50%;
    padding-top: 2%;
    text-align: left;
    flex-direction: column;
}
.textDiv{
    margin-top: 2%;
    width: 90%;
}
.contactTxtFirst{

    margin-top: 40px;
    margin-bottom: 25px;
    font-size: 50px;
    font-weight: bold;
    color: #163243;
}


.maintenanceTxt{
    font-size: 14px;
    margin-bottom: -25px;
    color: #163243;
}
.contactTxt{
    margin-top: 40px;
    margin-bottom: 25px;
    font-size: 50px;
    font-weight: bold;
    font-style: italic;
    color: #163243;
}
form{
    width:100%;
}
.obligatoire{
    font-size: 10px;
    margin-top: -20px;
    margin-bottom: 50px;

}
.form{
    position: relative;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
}
.inlineField{
    flex-direction: row;
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-bottom: 20px;
}
.inlineTxtFieldName{
    width: 35%;
}
.inlineTxtFieldMail{
    width: 60%;
}

.inlineTxt{
    width: 100%;
}
.header{
    width: 100%;
}

.formBottom{
    margin-top: 10px;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: space-between;
}
.desktopLogo{
    width: 200px;
    height: auto;
}

.divBtn{
    align-self: flex-start;
    flex-direction: row;
    width: 100%;
    margin-top: 15px;
    display: flex;
    position: relative;
}
.btnContainer{
    width: 120px;
    height: 70px;
    right: 0;
    position: absolute;
    display: flex;
}
.smiley{
    position: absolute;
    bottom: 0;
    right: -30px;
}


.underConstruction{
    width: 100%;
    height: auto;
    margin-left:10%;
}
#validationMail{
    display: flex;
    flex-direction: row;
    height: 150px;
    justify-content: start;
    text-align: center;
    align-content: center;
    align-items: center;

}
#validationMail p{
    width: 85%;
    font-size: 14px;
    text-align: left;
    padding-left: 10px;
    font-style: italic;
    color: #3691D0;
}
#btnRecontact{
    margin-top:15px;
    width: auto;
    background: #FFFFFF;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}
@media screen and (min-width: 951px) {
    .section {
        display: flex;
        justify-content: center;
        height: 70vh;
        min-height: 550px;
        max-height: 600px;
        max-width: 1200px;
        width: 85vw;
        align-items: center;
        justify-content: center;

    }
    .headerR {
        display:none;
    }
}

@media screen and (min-width: 790px) and (max-width: 950px) {
    .aside{
        display: none;
    }
    .section{
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .article{
        width: 100%;
    }
    .headerR {
        display: none;
    }

    .textDiv{
        width: 100%;
    }
    .form, form{
        width: 100%;
    }


}

@media screen and (max-width: 790px) {
    body{
        overflow-x: hidden;
    }
    .obligatoire{
        margin-bottom: 20px;
    }
    .aside {
        display: none;
    }
    .article{
        width: 100%;
        padding-left: 0;
        height: 100%;
        align-content: center;
        align-items: center;
    }
    .section{
        width: 100%;
        align-content: center;
        height: 100%;
    }
    .header{
        display: none;
    }
    .headerR{

        margin-left: 5%;
        position: relative;
        height: 200px;
        justify-content: center;
        align-content: center;
        align-items: center;
        display: flex;
        width: 90%;
    }
    .headerR img:nth-child(1){
        width: 100%;

    }

    .form{
        position: relative;
        width: 100%;
        display: flex;
        height: 50%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        justify-items: flex-start;
        min-height: 350px;
    }
    form{
        flex-direction: column;
        width: 90%;
        height: 100%;
        justify-content: space-between;
    }
    #validationMail{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        height: 150px;
        width: 100%;
        margin-left: 0;
        left: 0;
        justify-content: center;
        text-align: center;
        align-content: center;
        align-items: center;
    }
    #validationMail p{
        width: 60%;
        margin-left:20%;
        font-size: 14px;
        text-align: center;
        padding-left: 0;
        color: #3691D0;
    }
    #btnRecontact{
        margin-top: 50px;
        align-self: center;
    }
    .inlineField{
        flex-direction: column;
        justify-content: space-between;
        height: 30%;
    }
    .inlineTxtFieldName, .inlineTxtFieldMail{
        width: 100%;
    }
    .formBottom{
        justify-content: space-around;
        height: 60%;
    }
    .divBtn{
        width: 100%;
        margin: 0;
        height: 10px;
    }
    .btnContainer{
        width: 100%;
        height: 70px;
        display: flex;
    }
    .smiley{
        display: none;
    }

}

@media screen and (min-width: 1600px) {
    .divBtn {
        margin-top: 20px;
    }
}

.app-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.app-logo {
    padding-top: 52px;
    padding-bottom: 20px;
    max-width: 160px;
}

.app-section {
    text-align: center;
    border-radius: 1.5rem;
    box-shadow: 0 25px 50px -12px rgb(0 0 0/0.25);
    background-color: #ffffff;
    padding-inline: 0,75rem;
    padding-block: 1rem;
    margin-block: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 42rem;
    background-color: #77c7eb;
}
.app-section-bis {
    text-align: center;
    border-radius: 1.5rem;
    box-shadow: 0 25px 50px -12px rgb(0 0 0/0.25);
    background-color: #154E76;
    padding-inline: 0, 75rem;
    padding-block: 1rem;
    margin-block: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 42rem;
    background-color: #77c7eb;
}

.app-emma {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}

.app-title {
    color: #ffffff;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.75rem;
    width: 90%;
    padding-bottom: 16px;
    margin: 0;
}
.app-small {
    padding-bottom: 2.5rem;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
    color: #154e76;
}
.app-button {
    background-color: black;
    padding: 0;
    margin-inline: 8px;
    margin-block: 2px;
    border: none;
    border-radius: 9999px;
}

@media (min-width: 915px) {
    .app-section {
        padding-inline: 0;
        padding-block: 2rem;
        width: 75%;
    }
    .app-section-bis {
        padding-inline: 0;
        background-color: #154E76;
        padding-block: 2rem;
        width: 75%;
    }
    .app-title {
        font-size: 1.875rem;
        line-height: 2.25rem;
        width: 80%;
        padding-bottom: 1.5rem;
    }
    .app-small {
        padding-bottom: 1.75rem;
    }
    .app-emma {
        -webkit-transform: scale(1);
                transform: scale(1);
        padding-bottom: 32px;
    }
}

@media (min-width: 1280px) {
    .app-section {
        width: 50%;
    }
}

